.filter-white {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(195deg) brightness(107%) contrast(100%);
}

.gallery-item-header{
    display: flex;
    align-items: flex-start;

    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.7455357142857143) 0%, rgba(0,212,255,0) 100%);

    position: absolute;
    left: 10px; 
    right: 10px; 
    margin-left: auto; 
    margin-right: auto; 

    padding: 2%;

    z-index: 1;
}

.gallery-item-logo {
    height: 15%;
    width: 15%;
    opacity: 70%;

    margin-right: 5%;
    padding-right: 5%;

    border-right: 1px solid black;
}

.gallery-item-name {
    width: 100%;

    font-weight: bold;
    text-align: left;
    opacity: 80%;
}

.gallery-item-media {
    display: flex;

    position: absolute;
    
    bottom: 10px;
}

.gallery-item-media-box {
    opacity: 80%;
    width: 8%;
    margin-left: 2%;
    margin-bottom: 2%;
    background: black;

    z-index: 1;
}

.gallery-col {
    width: calc(33% - 20px);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}

.gallery-row {
    display: flex;
    justify-content: center;
}

.gallery-item-container {
    position:relative;
    display: inline-block;
    padding:10px;
    margin:10px;
    align-items: flex-start;
    color: black;
}

.gallery-item-container:hover .gallery-item-thumbnail{
    transform: scale(1.075);
    transition: all 200ms 50ms ease-in-out;
}

.gallery-item-container:hover {
    cursor: pointer;
}

.gallery-item-thumbnail-container {
    overflow: hidden;
}

.gallery-item-thumbnail {
    object-fit: cover;
    transition: all 100ms ease-in-out;
}

.gallery-item-animate {
    animation: fade-in 1s;
  }
  
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 100;
        transform: translateY(0px);
    }
}

.filter-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter-nav-dropdowns {
    display: flex;
    flex-direction: row;
}

.filter-nav-dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin: 16px;
}
  
.filter-nav-dropdown {
    position: relative;
    display: inline-block;
}

.filter-nav-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.filter-nav-dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

/* Change color of dropdown links on hover */
.filter-nav-dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.filter-nav-dropdown:hover .filter-nav-dropdown-content {
display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.filter-nav-dropdown:hover .filter-nav-dropbtn {
background-color: #3e8e41;
}

img {
    width: 100%;
}

@media (max-width: 991.98px) {
    .gallery-col{
        width: calc(100% - 40px);
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
    }
    .gallery-row{
        display: block;
    }
}