.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-style: groove;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.726);
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

.left-arrow:hover {
    background-color: rgb(206, 206, 206);
}

.right-arrow:hover {
    background-color: rgb(206, 206, 206);
}

.dot-show {
    transition: all 250ms linear 2s;
}

.dot-hidden {
    opacity: 0%;
}

.dot-container {
    z-index: 1;
    
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot, .highlighted-dot {
    height: 0.75vw;
    width: 0.75vw;

    border-radius: 50%;
    display: inline-block;
    margin: 1%;
}

.dot {
    background-color: rgba(206, 206, 206, 0.8);
}

.highlighted-dot {
    background-color: rgba(145, 145, 145, 1.0);
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) and (min-width: 767.99px) { 
    .dot .highlighted-dot {
        height: 2vw;
        width: 2vw;
    }
}