.preview-image {
    width: 97%;
    height: 50%;

    object-fit: cover;

    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);

    outline: 2px solid black;
}

.featured-item-background {
    background-size: contain;
}

.featured-item-container {
    padding: 5%;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    aspect-ratio: 1 / 1.4;
}

.featured-item-card {
    border-color: black;
    border-style: solid;
    border-width: 13px;
    margin: 10%;

    font-family: 'Cardo', serif;
}

.featured-item-link {
    text-decoration: none;
}

.description-text {  
    color: black;

    width: 97%;
    height: 33%;

    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;

    border-style:inset;
    border-color: rgba(255, 255, 255, 0.5);

    outline: 2px solid black;
}

.description-text p {
    font-size: 15pt;
    font-family: 'Cardo', serif;
}

.featured-item-title {
    color: black;

    display: inline-block;
    white-space: nowrap;
    width: 100%;

    margin: 0;

    padding-top: 1%;
    padding-bottom: 1%;

    font-weight: 700;
}

.title-container {
    width: 100%;

    border-style: outset;
    z-index: 1;

    outline: 2px solid black;
    
    padding-left: 3%;
    padding-right: 3%;
}

.fit-container {
    width: 100%;
}

.featured-item-type {
    color: black;

    width: 100%;

    margin: 0;

    z-index: 1;

    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;

    border-style: outset;
    border-color: rgba(255, 255, 255, 0.5);

    outline: 2px solid black;

    font-weight: 700;
}

.featured-item-notes {
    width: 100%;
    margin: 3%;
}

.featured-item-creators {
    color: black;
    font-size: 12pt;
}

.featured-item-date {
    color: black;
    font-size: 11pt;
}

.featured-item-notes p {
    margin: 0;
}