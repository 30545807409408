@import url('fonts.css');

* {
    /*main website colours*/
    --primary-colour: #ebf3ef;
    --primary-colour-light: color-mix(in srgb, var(--primary-colour) 30%, white 70%);
    --primary-colour-dark: color-mix(in srgb, var(--primary-colour) 90%, black 10%);

    --secondary-colour: #121212;

    /*accent colours for the different pages*/
    --art-colour: 127, 171, 100;
    --game-colour: 172, 67, 70;
    --shader-colour: 208, 118, 58;
    --programming-colour: 47, 126, 161;

}

body {
    background: var(--primary-colour);
}

h1 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
    font-style: italic;
}

h3 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
    font-style: italic;
}

.page {
    min-height: 1000px;
}

.page-container, .light-section, .dark-section, .standard-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.light-section, .dark-section, .standard-section, .thin-section, .wide-section, .normal-section {
    padding-top: 20px;
}

.light-section {
    background-color: var(--primary-colour-light);
    width: 100%;
}

.standard-section {
    background-color: var(--primary-colour);
    width: 100%;
}

.dark-section {
    background-color: var(--primary-colour-dark);
    width: 100%;
}

.wide-section {
    width: 80%;
    max-width: 1500px;
    margin-bottom: 50px;
}

.normal-section {
    width: 80%;
    max-width: 1300px;
    margin-bottom: 50px;
}

.thin-section {
    width: 80%;
    max-width: 1200px;
    margin-bottom: 50px;
}

p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16pt;
}