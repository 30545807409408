.filter-white {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(195deg) brightness(107%) contrast(100%);
}

.nav-bar {
    background: var(--secondary-colour);

    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;

    padding: 20px 3%;
}

.sm-icons {
    flex-direction: row;
}

@media only screen and (max-width: 960px) {
    .sm-icons .nav-item {
      padding-right: 1em;
    }
}

@media (min-width: 992px) {
    .nav-dir, #nav-down{
        padding: 0 8px;
        margin: auto;
    }

    .nav-dir:hover {
        transform: scale(1.1);
    }
}