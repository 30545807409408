.DisplayModal {
    height: 100%;
    width: 100%;
}

.MediaDisplayImage {

}

.BreakdownItem {
    margin: 10%;
}